import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import scroll from "vue3-seamless-scroll"; // px 自适应

// 项目样式初始化
import '@/styles/init.css'

// 项目样式主题化
import '@/styles/theme.scss'

// PC端 rem 适配
import "amfe-flexible/index"

// 引入 Element-Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入图片懒加载插件
// 页面中 img 使用 v-lazy 指令来延迟加载图像lh 
import Lazyload from "vue3-lazyload";


createApp(App).use(store).use(router).use(ElementPlus).use(Lazyload).use(scroll).mount('#app')
